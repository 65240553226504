<template>
  <b-modal
    id="add-coupon-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>Adicionar cupom</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="text-center mt-3">
      <p>Adicione um cupom para <strong>{{ advertiser.name }}</strong>.</p>
      <form class="default-form" @submit.prevent="submit">
        <div class="form-group mb-3">
        <input type="text" class="form-control" placeholder="Código" v-model.trim="$v.form.code.$model" />
      </div>
        <div>
          <Button
            type="submit"
            class="btn btn-primary mt-4"
            :disabled="$v.$invalid"
            :loading="loader"
          >
            Adicionar cupom
          </Button>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'
import { required } from 'vuelidate/lib/validators'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  props: ['advertiser'],
  name: '',
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      loader: false,
      content: null,
      content_type: 'error',
      form: {
        code: ''
      }
    }
  },
  validations () {
    return {
      form: {
        code: { required }
      }
    }
  },
  methods: {
    /**
     * Hidden
     */
    hidden () {
      this.form.code = ''
    },
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      this.content = null
      try {
        await AdvertisersService.addCouponToAdvertiser({
          id: this.advertiser.id,
          code: this.form.code
        })
        const notification = {
          notification: {
            type: 'success',
            content: 'Cupom adicionado com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$bvModal.hide('add-coupon-modal')
        this.$emit('coupon-added')
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
