<template>
  <div>
    <form @submit.prevent="submit" class="text-left default-form">
      <div class="form-group mb-3">
        <label class="form-label fw-bold">Nome</label>
        <input type="text" class="form-control" placeholder="Nome" v-model.trim="form.name" />
      </div>
      <div class="form-group mb-3">
        <label class="form-label fw-bold">{{ valuesLabel }}</label>
        <p class="font-size-xSmall">{{ valuesDescription }}</p>
        <b-form-tags
          :input-id="`tags-separators-${targeting ? targeting.id : ''}`"
          v-model="form.values"
          separator=" ,;"
          placeholder="Digite..."
          remove-on-delete
        ></b-form-tags>
      </div>
      <div class="form-group mb-3">
        <label class="form-label fw-bold">ou faça o upload da planilha</label><br/>
        <p class="font-size-xSmall">Para adicionar dados 1st Party (próprios), utilize a planilha anexa como modelo. Adicione seus dados, e vincule na campanha desejada.<br>
          <a v-if="type === 'EMAIL_AUDIENCE'" href="/models/planilha-modelo-audiencias.csv" class="font-size-small text-decoration-underline me-3" target="_blank" download><i class="fa fa-paperclip" aria-hidden="true"></i> Modelo de Audiência CSV</a>
          <a v-if="type === 'EMAIL_AUDIENCE'" href="/models/planilha-modelo-audiencias.xlsx" class="font-size-small text-decoration-underline" target="_blank" download><i class="fa fa-paperclip" aria-hidden="true"></i> Modelo de Audiência XLSX</a>
          <a v-if="type === 'SITE_LIST'" href="/models/planilha-modelo-sites.csv" class="font-size-small text-decoration-underline me-3" target="_blank" download><i class="fa fa-paperclip" aria-hidden="true"></i> Modelo de Sites CSV</a>
          <a v-if="type === 'SITE_LIST'" href="/models/planilha-modelo-sites.xlsx" class="font-size-small text-decoration-underline" target="_blank" download><i class="fa fa-paperclip" aria-hidden="true"></i> Modelo de Sites XLSX</a>
        </p>
        <input type="file" @change="changeFile" accept=".csv, .xlsx" />
        <p class="font-size-xSmall">Apenas arquivos CSV ou XLSX</p>
      </div>
      <div class="text-center mt-3">
        <!-- MESSAGES -->
        <div
          class="mb-2 alert-message-card text-color2 font-size-small px-3 py-2"
          v-if="loader"
        >
          <p
            class="mb-0"
          >
            Essa ação pode levar alguns minutos.
          </p>
        </div> <!-- END MESSAGES -->
        <Button
          type="submit"
          class="btn btn-primary"
          :loading="loader"
        >
          {{ targeting ? 'Salvar' : 'Criar' }}
        </Button>
      </div>
    </form>
  </div>
</template>

<script>

import CustomTargetingsService from '@/modules/custom-targetings/services/custom-targetings-service'

import Button from '@/components/common/Button/Button'

export default {
  name: 'UpsertCustomTargetingForm',
  props: ['advertiser', 'targeting', 'type', 'values'],
  components: {
    Button
  },
  data () {
    return {
      loader: false,
      form: {
        advertiser_id: '',
        name: '',
        type: '',
        values: [],
        file: ''
      }
    }
  },
  created () {
    if (this.advertiser) this.form.advertiser_id = this.advertiser.id
    if (this.targeting) this.form.name = this.targeting.name
    if (this.type) this.form.type = this.type
    if (this.values) this.form.values = this.values
  },
  computed: {
    /**
     * Values description
     */
    valuesDescription () {
      switch (this.type) {
        case 'EMAIL_AUDIENCE':
          return 'Separe os endereços de email por vírgulas ou espaços'
        case 'SITE_LIST':
          return 'Separe os endereços dos sites por vírgulas ou espaços (os endereços precisam começar com https://)'
        default:
          return ''
      }
    },
    /**
     * Values label
     */
    valuesLabel () {
      switch (this.type) {
        case 'EMAIL_AUDIENCE':
          return 'Endereços de email'
        case 'SITE_LIST':
          return 'Endereços dos sites'
        default:
          return ''
      }
    }
  },
  methods: {
    /**
     * Handle change on file
     */
    changeFile (e) {
      const file = e.target.files[0]
      this.form.file = file
    },
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      try {
        let targeting = null

        if (this.targeting) {
          targeting = await CustomTargetingsService.updateCustomTargeting(this.targeting.id, {
            name: this.form.name,
            values: this.form.values,
            file: this.form.file
          })
          this.$emit('custom-targeting-updated', targeting)
        } else {
          targeting = await CustomTargetingsService.createCustomTargeting({
            advertiserId: this.form.advertiser_id,
            name: this.form.name,
            type: this.form.type,
            values: this.form.values,
            file: this.form.file
          })
          this.$emit('custom-targeting-created', targeting)
        }
      } catch (error) {
        this.$emit('upsert-custom-targeting-error', error)
      }
      this.loader = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .b-form-tag-remove {
    padding: 0;
    background-color: transparent;
    border: 0;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }
  .badge-secondary {
    color: #fff;
    background-color: $color9;
  }

  .b-form-tags-button {
    display: none;
  }
}
.b-form-tags-list {
  margin-top: -.25rem;
}
.b-form-tags-list .b-form-tag, .b-form-tags-list .b-form-tags-field {
  margin-top: .25rem;
}
.b-form-tag {
  font-size: 75%;
  font-weight: 400;
  line-height: 1.5;
  margin-right: .25rem;
}
.mw-100 {
  max-width: 100% !important;
}
.b-form-tag > button.b-form-tag-remove {
  color: inherit;
  font-size: 125%;
  line-height: 1;
  float: none;
  margin-left: .25rem;
}
</style>
