<template>
  <b-modal
    id="replicate-order-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>Repetir pagamento</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="text-center mt-3">
      <template v-if="!newOrder && order">
        <div class="mb-4">
          <i class="fa fa-warning font-size-large"></i>
        </div>
        <p>Você tem certeza que deseja repetir o pagamento de valor <strong>{{ order.amount/ 100 | formatMoney }}</strong> do anunciante <strong>{{ order.advertiser.name }}</strong>?</p>
        <Button
          type="button"
          class="btn btn-primary mt-4"
          @click="submit"
          :loading="loader"
        >
          Repetir pagamento
        </Button>
      </template>
      <!-- ORDER DETAILS -->
      <template v-else-if="newOrder">
        <p><strong>Ordem de pagamento criada com sucesso!</strong></p>
        <p>
          <strong>Anunciante:</strong> {{ newOrder.advertiser.name }}<br/>
          <strong>Valor:</strong> {{ (newOrder.amount / 100) | formatMoney }}
        </p>
        <p>
         Para realizar o pagamento, <a :href="newOrder.payment_url" class="link-block-color1" target="_blank">clique aqui</a>.
        </p>
        <p>
          Caso prefira, copie e cole o endereço do nosso parceiro PagarMe em seu navegador.
        </p>
        <p>
          <strong>{{ newOrder.payment_url }}</strong>
        </p>
      </template> <!-- END ORDER DETAILS -->
    </div>
  </b-modal>
</template>

<script>

import NotificationService from '@/modules/notifications/services/notifications-service'
import OrdersService from '@/modules/orders/services/orders-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'CopyOrderModal',
  props: ['order'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      newOrder: null
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.newOrder = false
    },
    /**
     * On modal shown
     */
    shown () {
    },
    /**
     * Get order
     */
    async submit () {
      this.loader = true
      try {
        const order = await OrdersService.replicateOrder(this.order.id)
        this.newOrder = order
        const notification = {
          notification: {
            type: 'success',
            content: 'Pagamento repetido com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$emit('order-replicated', order)
        // this.$bvModal.hide('replicate-order-modal')
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
