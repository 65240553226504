<template>
  <b-modal
    id="destroy-advertiser-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>Desativar anunciante</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="text-center mt-3">
      <div class="mb-4">
        <i class="fa fa-warning font-size-large"></i>
      </div>
      <p>Você tem certeza que deseja desativar o anunciante <strong>{{ this.advertiser.name }}</strong>?</p>
      <p>Essa ação acarretará na pausa de todas as campanhas em andamento desse anunciante.</p>
      <div class="mt-3">
        <!-- MESSAGES -->
        <div
          class="mb-2 alert-message-card text-color2 font-size-small px-3 py-2"
          v-if="loader"
        >
          <p
            class="mb-0"
          >
            Essa ação pode levar alguns minutos.
          </p>
        </div> <!-- END MESSAGES -->
        <Button
          type="button"
          class="btn btn-primary"
          @click="submit"
          :loading="loader"
        >
          Desativar anunciante
        </Button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'DestroyAdvertiserModal',
  props: ['advertiser'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      loader: false,
      content: null,
      content_type: 'error'
    }
  },
  methods: {
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      try {
        await AdvertisersService.destroyAdvertiser(this.advertiser.id)
        const notification = {
          notification: {
            type: 'success',
            content: 'Anunciante desativado com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$bvModal.hide('destroy-advertiser-modal')
        if (this.advertiser) this.$emit('advertiser-destroyed', this.advertiser)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
