<template>
  <b-modal
    id="issue-order-nfse-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>Emitir nota fiscal</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="text-center mt-3">
      <template v-if="order">
        <p>Você tem certeza que deseja emitir a nota fiscal do pagamento de valor <strong>{{ order.amount/ 100 | formatMoney }}</strong> do anunciante <strong>{{ order.advertiser.name }}</strong>?</p>
        <Button
          type="button"
          class="btn btn-primary mt-4"
          @click="submit"
          :loading="loader"
        >
          Emitir nota fiscal
        </Button>
      </template>
    </div>
  </b-modal>
</template>

<script>

import NotificationService from '@/modules/notifications/services/notifications-service'
import OrdersService from '@/modules/orders/services/orders-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'IssueOrderNFSeModal',
  props: ['order'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
    },
    /**
     * On modal shown
     */
    shown () {
    },
    /**
     * Get order
     */
    async submit () {
      this.loader = true
      try {
        await OrdersService.issueNFSe(this.order.id)
        const notification = {
          notification: {
            type: 'success',
            content: 'Nota fiscal emitida com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$emit('order-nfse-issued', this.order)
        this.$bvModal.hide('issue-order-nfse-modal')
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
