<template>
  <div class="campaign-list-table">
    <div v-if="loader && localCampaigns.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else>
      <!-- FILTERS -->
      <div class="d-flex align-items-center mb-4 default-form">
        <span class="me-4">Filtros</span>
        <div class="me-3">
          <span class="fw-bold form-label me-3">Nome:</span>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="filters.name"
            @keyup.enter.prevent="search"
          />
        </div>
        <div class="me-3">
          <span class="fw-bold form-label me-3">Status:</span><br/>
          <div class="custom-select custom-select-sm">
            <select
              v-model="filters.status"
              class="form-control form-control-sm"
            >
              <option value="">Todos</option>
              <option value="ACTIVE">Ativo</option>
              <option value="FINISHED">Encerrado</option>
              <option value="PAUSED">Pausado</option>
              <option value="DRAFT">Rascunho</option>
            </select>
          </div>
        </div>
        <div>
          <Button
            type="button"
            class="btn-primary btn-xs mt-3"
            @click="search"
          >
            Pesquisar
          </Button>
        </div>
      </div> <!-- END FILTERS -->
      <!-- CARD -->
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Anunciante</th>
                  <th>Campanha</th>
                  <th>Tipo</th>
                  <th>Status</th>
                  <th>Início</th>
                  <th>Término</th>
                  <th>Orçamento</th>
                  <th>Gasto</th>
                  <th>Impressões</th>
                  <th>Cliques</th>
                  <th>Conversões</th>
                  <th>CTR</th>
                  <th>CPM</th>
                  <th>CPA</th>
                  <th>Categoria</th>
                  <th width="100">
                    Detalhes
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-nowrap" v-for="(campaign, index) in localCampaigns" :key="index">
                  <td>
                    <router-link :to="{ name: 'advertisers.show', params: { id: campaign.advertiser.id } }" class="link-color3">
                      {{ campaign.advertiser.name }}
                    </router-link>
                  </td>
                  <td>
                    <router-link :to="{ name: 'campaigns.show', params: { id: campaign.id } }" class="link-color3">
                      {{ campaign.name }}
                    </router-link>
                  </td>
                  <td>{{ campaign.type | formatCampaignType }}</td>
                  <td>{{ campaign.status | formatCampaignStatus }}</td>
                  <td>{{ campaign.start_date | formatDate }}</td>
                  <td>{{ campaign.end_date | formatDate }}</td>
                  <td>{{ campaign.amount / 100 | formatMoney }}</td>
                  <td>{{ campaign.spending / 100 | formatMoney }}</td>
                  <td>{{ campaign.impressions | formatNumber }}</td>
                  <td>{{ campaign.clicks | formatNumber }}</td>
                  <td>{{ campaign.conversions | formatNumber }}</td>
                  <td>{{ campaign.ctr }}%</td>
                  <td>{{ campaign.cpm / 100 | formatMoney }}</td>
                  <td>{{ campaign.cpa / 100 | formatMoney }}</td>
                  <td>{{ campaign.category.name }}</td>
                  <td>
                    <router-link :to="{ name: 'campaigns.show', params: { id: campaign.id } }" class="link-color3" v-b-tooltip.hover title="Ver mais">
                      <i class="fa fa-eye"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-4">
            <Button
              class="btn-primary btn-sm"
              :loading="loader"
              @click="loadMore"
              v-if="isLoadMoreVisible"
            >
              Carregar mais
            </Button>
          </div>
        </div>
      </div> <!-- END CARD -->
    </template>
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import { mapState } from 'vuex'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'CreativeListTable',
  props: ['advertiserId', 'campaigns'],
  components: {
    Button,
    Loader
  },
  data () {
    return {
      filters: {
        name: '',
        status: ''
      },
      localCampaigns: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.campaigns) {
      this.localCampaigns = this.campaigns
      this.isLoadMoreVisible = false
    } else {
      this.getCampaigns()
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Get campaigns
     */
    async getCampaigns () {
      if (!this.user) return false
      this.loader = true
      try {
        const campaigns = await CampaignsService.getCampaigns({
          advertiserId: this.advertiserId,
          companyId: this.advertiserId ? null : this.user.company.id,
          name: this.filters.name,
          status: this.filters.status,
          page: this.page
        })
        this.localCampaigns = [...this.localCampaigns, ...campaigns.data]
        this.isLoadMoreVisible = campaigns.meta.current_page !== campaigns.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more campaigns
     */
    loadMore () {
      this.page++
      this.getCampaigns()
    },
    /**
     * Search
     */
    search () {
      this.page = 1
      this.localCampaigns = []
      this.getCampaigns()
    }
  }
}
</script>
