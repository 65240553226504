<template>
  <b-modal
    id="create-creative-modal"
    :modal-class="modalClasses"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>Adicionar criativo</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="text-center mt-3">
      <UpsertCreativeForm
        :advertiserId="advertiserId"
        :format="format"
        @creative-created="handleCreated"
        @upsert-creative-error="handleError"
        @format-selected="handleFormatSelected"
      />
    </div>
  </b-modal>
</template>

<script>

// import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
// import CreativesService from '@/modules/creatives/services/creatives-service'
// import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
// import Button from '@/components/common/Button/Button'
// import Loader from '@/components/common/Loader/Loader'
import UpsertCreativeForm from '@/components/creatives/UpsertCreativeForm'

export default {
  name: 'CreateCreativeModal',
  props: ['advertiserId', 'format'],
  components: {
    AlertWraper,
    // Button
    // Loader,
    UpsertCreativeForm
  },
  data () {
    return {
      loader: false,
      content: null,
      content_type: 'error',
      selected_format: ''
    }
  },
  computed: {
    modalClasses () {
      const classes = ['default-modal']

      if (this.selected_format === 'NATIVE_DISPLAY' || this.format === 'NATIVE_DISPLAY') classes.push('modal-size-lg')

      return classes.join(' ')
    }
  },
  methods: {
    /**
     * Handle created
     */
    handleCreated (creative) {
      this.$bvModal.hide('create-creative-modal')
      this.$emit('creative-created', creative)
    },
    /**
     * Handle error
     */
    handleError (error) {
      this.content = parseErrorResponseToArray(error)
    },
    /**
     * Handle format selected
     */
    handleFormatSelected (format) {
      this.selected_format = format
    },
    /**
     * Hidden
     */
    hidden () {
      this.content = null
      this.selected_format = ''
    },
    /**
     * Shown
     */
    shown () {
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
