<template>
  <b-modal
    :id="localModalId"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hidden="reset"
    @shown="shown"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ title }}</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div>
      <UpsertCustomTargetingForm
        :advertiser="advertiser"
        :targeting="targeting"
        :type="type"
        @custom-targeting-created="handleSubmit"
        @custom-targeting-updated="handleSubmit"
        @upsert-custom-targeting-error="handleError"
      />
    </div>
  </b-modal>
</template>

<script>

import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import UpsertCustomTargetingForm from '@/components/custom-targetings/UpsertCustomTargetingForm'

export default {
  name: 'UpsertCustomTargetingModal',
  props: ['advertiser', 'modalId', 'targeting', 'type'],
  components: {
    AlertWraper,
    UpsertCustomTargetingForm
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false
    }
  },
  computed: {
    buttonText () {
      return this.targeting ? 'Salvar' : 'Criar'
    },
    /**
     * Modal id
     */
    localModalId () {
      return this.modalId ? this.modalId : 'upsert-custom-targeting-modal'
    },
    submitMessage () {
      if (this.type === 'SITE_LIST') {
        return this.targeting ? 'Lista editada com sucesso' : 'Lista criada com sucesso'
      } else if (this.type === 'EMAIL_AUDIENCE') {
        return this.targeting ? 'Audiência editada com sucesso' : 'Audiência criada com sucesso'
      }
      return ''
    },
    title () {
      if (this.type === 'SITE_LIST') {
        return this.targeting ? 'Editar lista de sites' : 'Criar lista de sites'
      } else if (this.type === 'EMAIL_AUDIENCE') {
        return this.targeting ? 'Editar audiência' : 'Criar audiência'
      }
      return ''
    }
  },
  methods: {
    /**
     * Handle error
     */
    handleError (error) {
      this.content = parseErrorResponseToArray(error)
    },
    /**
     * Handle submit
     */
    handleSubmit (customTargeting) {
      const notification = {
        notification: {
          type: 'success',
          content: this.submitMessage
        }
      }
      NotificationService.createGlobalSuccessNotification(notification)
      this.$bvModal.hide(this.localModalId)
      if (this.targeting) this.$emit('custom-targeting-updated', customTargeting)
      else this.$emit('custom-targeting-created', customTargeting)
    },
    reset () {},
    /**
     * On modal shown
     */
    shown () {}
  }
}
</script>
