<template>
  <b-modal
    id="destroy-coupon-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>Remover cupom</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="text-center mt-3">
      <div class="mb-4">
        <i class="fa fa-warning font-size-large"></i>
      </div>
      <p>Você tem certeza que deseja remover o cupom <strong>{{ coupon.code }}</strong> do anunciante <strong>{{ advertiser.name }}</strong>?</p>
      <p><strong>Atenção:</strong> ao remover o cupom, ele não poderá ser adicionado novamente e seus benefícios deixarão de fazer efeito.</p>
      <div>
        <Button
          @click="submit"
          class="btn btn-primary mt-4"
          :loading="loader"
        >
          Remover cupom
        </Button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  props: ['advertiser', 'coupon'],
  name: 'RemoveCouponModal',
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      loader: false,
      content: null,
      content_type: 'error'
    }
  },
  methods: {
    /**
     * Hidden
     */
    hidden () {
      this.form.code = ''
    },
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      this.content = null
      try {
        await AdvertisersService.removeActiveCouponFromAdvertiser(this.advertiser.id)
        const notification = {
          notification: {
            type: 'success',
            content: 'Cupom removido com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$bvModal.hide('destroy-coupon-modal')
        this.$emit('coupon-destroyed')
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
