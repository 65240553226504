<template>
  <div>
    <router-link :to="{ name: 'campaigns.create' }" class="btn btn-primary">
      {{ buttonText }}
    </router-link>
  </div>
</template>

<script>

// import CreateCampaignModal from '@/components/campaigns/CreateCampaignModal'

export default {
  name: 'CreateCampaignButton',
  props: ['text'],
  computed: {
    /**
     * Get button text
     */
    buttonText () {
      return this.text ? this.text : 'Nova campanha'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
