<template>
  <div class="creative-list-table">
    <div v-if="loader && localCreatives.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <!-- CARD -->
    <div class="card" v-else>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Formato</th>
                <th>Tamanho</th>
                <th>Status</th>
                <th>Detalhes</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(creative, index) in localCreatives"
                :key="index"
              >
                <td>
                  <a
                    href="#"
                    class="link-color3"
                    v-b-modal="`creative-detail-modal-${creative.id}`"
                    @click.prevent
                  >
                    {{ creative.name }}
                  </a>
                </td>
                <td>{{creative.format | formatCreativeFormat }}</td>
                <td>{{ creative.layout }}</td>
                <td>
                  {{ creative.status | formatStatus }}
                  <template v-if="creative.status === 'ERROR'">
                    <RetryCreativeCreationOnPlatformButton
                      :creativeId="creative.id"
                      @retry-successful="reset"
                      @retry-error="handleRetryError"
                    />
                  </template>
                </td>
                <td>
                  <a
                    href="#"
                    class="link-color2"
                    v-b-tooltip.hover title="Ver mais"
                    v-b-modal="`creative-detail-modal-${creative.id}`"
                    @click.prevent
                  >
                    <i class="fa fa-eye"></i>
                  </a>
                  <CreativeDetailModal
                    :creative="creative"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center mt-4">
          <Button
            class="btn-primary btn-sm"
            :loading="loader"
            @click="loadMore"
            v-if="isLoadMoreVisible"
          >
            Carregar mais
          </Button>
        </div>
      </div> <!-- END CARD -->
    </div>
  </div>
</template>

<script>

import CreativesService from '@/modules/creatives/services/creatives-service'

import Button from '@/components/common/Button/Button'
import CreativeDetailModal from '@/components/creatives/CreativeDetailModal'
import Loader from '@/components/common/Loader/Loader'
import RetryCreativeCreationOnPlatformButton from '@/components/creatives/RetryCreativeCreationOnPlatformButton'

export default {
  name: 'CreativeListTable',
  props: ['advertiserId', 'lineId', 'creatives', 'reload'],
  components: {
    Button,
    CreativeDetailModal,
    Loader,
    RetryCreativeCreationOnPlatformButton
  },
  data () {
    return {
      localCreatives: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.creatives) {
      this.localCreatives = this.creatives
      this.isLoadMoreVisible = false
    } else {
      this.getCreatives()
    }
  },
  watch: {
    reload (newValue) {
      if (newValue) this.reset()
    }
  },
  methods: {
    /**
     * Get creatives
     */
    async getCreatives () {
      if (!this.advertiserId && !this.lineId) return false

      this.loader = true
      try {
        let creatives = []
        if (this.advertiserId) {
          creatives = await CreativesService.getCreatives({
            advertiserId: this.advertiserId,
            page: this.page
          })
        } else if (this.lineId) {

        }
        this.localCreatives = [...this.localCreatives, ...creatives.data]
        this.isLoadMoreVisible = creatives.meta.current_page !== creatives.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Handle retry error
     */
    handleRetryError (error) {
      error.handleGlobally && error.handleGlobally()
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getCreatives()
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localCreatives = []
      this.getCreatives()
    }
  }
}
</script>
